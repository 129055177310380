<template>
  <v-card class="mb-7">
    <v-card-text class="pa-5">
      <h3 class="title blue-grey--text text--darken-2 font-weight-regular">
        Dados gerais
      </h3>
      <div class="mt-4">
        <v-row>
          <v-col cols="12" sm="2" class="d-flex align-center">
            <label>Nome</label>
          </v-col>
          <v-col cols="12" sm="10">
            <v-text-field
              filled
              background-color="transparent"
              v-model="perfilEditavel.name"
              placeholder="Nome"
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="2" class="d-flex align-center">
            <label>Email</label>
          </v-col>
          <v-col cols="12" sm="10">
            <v-text-field
              filled
              background-color="transparent"
              v-model="perfilEditavel.email"
              readonly
              disabled
              placeholder="Enter Email"
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>

        <v-card-actions class="mt-5">
          <v-spacer></v-spacer>
          <buttons-cadastro @close="reset" @save="save" :disabled="!canSave"/>
        </v-card-actions>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ButtonsCadastro from '@/components/ButtonsCadastro/ButtonsCadastro.vue'

export default {
  components: {
    ButtonsCadastro
  },
  data() {
    return {
      perfilEditavel: {},
      canSave: false
    }
  },
  watch: {
    "perfilEditavel.name": function(newVal) {
      if (newVal === this.perfilUsuario.name) {
        this.canSave = false
      }
      else {
        this.canSave = true
      }
    }
  },
  created() {
    this.perfilEditavel = {...this.perfilUsuario}
  },
  computed: {
    ...mapState('auth', ['perfilUsuario']),
  },
  methods: {
    ...mapActions('auth', ['updatePerfilUsuario']),
    ...mapActions('template', ['successMessage', 'errorMessage']),
    reset() {
      Object.assign(this.perfilEditavel, this.perfilUsuario)
    },
    async save() {
      try {
        await this.updatePerfilUsuario(this.perfilEditavel)
        this.successMessage('Perfil atualizado com sucesso.')
      } catch (error) {
        this.errorMessage('Erro ao atualizar perfil.')
        this.reset()
      }
    },
  }
}
</script>