<template>
  <v-card class="mb-7">
    <v-card-text class="pa-5">
      <h3 class="title blue-grey--text text--darken-2 font-weight-regular">
        Trocar Senha
      </h3>
      <div class="mt-4">
        <validation-observer v-slot="{ invalid }">
          <v-row v-if="!isManager">
            <v-col cols="12" sm="2" class="d-flex align-center">
              <label>Senha Atual</label>
            </v-col>
            <v-col cols="12" sm="10">
              <validation-provider
                v-slot="{ errors }"
                name="Senha Atual"
                rules="required"
              >
                <v-text-field
                  background-color="transparent"
                  v-model="senha.old_password"
                  label="Senha Atual"
                  type="password"
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
          <password
            class="d-flex justify-center w-100"
            v-model="senha.password"
            label="Nova Senha"
            :cols="12"
            :sm="10"
            :md="4"
          />

          <v-card-actions>
            <v-spacer></v-spacer>
            <buttons-cadastro
              @close="senha = {}"
              @save="updatePassword"
              :disabled="invalid"
            />
          </v-card-actions>
        </validation-observer>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import ButtonsCadastro from '@/components/ButtonsCadastro/ButtonsCadastro.vue'
import Password from '@/components/Password/Password'

export default {
  props: {
    isManager: {
      type: Boolean,
      default: false,
    },
    usuario: {
      type: String,
      default: '',
    },
  },
  components: {
    ButtonsCadastro,
    Password,
  },
  data() {
    return {
      senha: {},
    }
  },
  methods: {
    ...mapActions('auth', ['changePassword']),
    ...mapActions('template', ['successMessage', 'errorMessage']),
    async updatePassword() {
      try {
        let novaSenha
        if (this.isManager) {
          novaSenha = {
            new_password: this.senha.password,
            confirm_password: this.senha.password,
          }
        } else {
          novaSenha = {
            ...this.senha,
            password_confirm: this.senha.password,
          }
        }
        await this.changePassword({
          passwordInfo: novaSenha,
          isManager: this.isManager,
          usuario: this.usuario,
        })
        this.successMessage('Senha atualizada com sucesso.')
      } catch (error) {
        if (error.response) {
          if (
            error.response.status == 400 &&
            error.response.data.old_password
          ) {
            this.errorMessage('Senha atual inválida')
          } else if (
            error.response.status == 400 &&
            error.response.data.new_password
          ) {
            this.errorMessage(error.response.data.new_password[0])
          }
        } else if (error.message) {
          this.errorMessage(error.message)
        } else {
          this.errorMessage(error)
        }
        // this.errorMessage('Erro ao trocar a senha')
      }
      this.$forceUpdate()
      this.senha = {}
    },
  },
}
</script>

<style scoped>
.password-help-text::before {
  content: '\1F512';
}
</style>
