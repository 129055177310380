<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <v-row>
      <v-col cols="12">
        <perfil-geral />
        <trocar-senha />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PerfilGeral from '@/components/usuarios/PerfilGeral'
import TrocarSenha from '@/components/usuarios/TrocarSenha'

export default {
  data() {
    return {
      page: {
        title: 'Meu Perfil',
      },
      breadcrumbs: [
        {
          text: 'Usuários',
          disabled: true,
          to: '#',
        },
        {
          text: 'Perfil',
          disabled: true,
        },
      ],
    }
  },
  components: {
    PerfilGeral,
    TrocarSenha,
  },
}
</script>